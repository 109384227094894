import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Box, Grid, Tooltip } from "@material-ui/core";
import PubSub from 'pubsub-js';
import "./style.css";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";

import TicketService from "../../services/Tickets";
import ButtonWithSpinner from "../ButtonWithSpinner";
import Icon from "../Icon";
import { i18n } from "../../translate/i18n";
import TransferTicketModal from "../TransferTicketModal";

const TicketListItemClosed = ({ selectedQueueIds = [], selectedTagsIds = [], visibility }) => {
    const history = useHistory();
    const { ticketId } = useParams();
    const [tickets, setTickets] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState();
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const pageSize = 15;

    const fetchClosedTickets = async () => {
        setIsLoading(true);
        try {
            const data = await TicketService.getTicketsByStatus(pageSize, pageNumber, "closed", selectedQueueIds, selectedTagsIds, visibility);
            const { tickets: newTickets, hasMore } = data;
            setTickets((prevTickets) => [...prevTickets, ...newTickets]);
            setHasMore(hasMore);
        } catch (error) {
            console.error("Erro ao buscar tickets abertos:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (hasMore && !isLoading) {
            fetchClosedTickets();
        }
    }, [pageNumber, hasMore, tickets]);

    useEffect(() => {
        const handleTicketUpdates = () => {
            const listener = PubSub.subscribe("TICKET_ACTION_UPDATED", async (_, { newStatus, ticketId }) => {
                setTickets((prevTickets) => {
                    if (newStatus === "transferTicket") {
                        return prevTickets.filter((ticket) => ticket.id !== ticketId);
                    }
                    return prevTickets;
                });
            });

            return () => {
                PubSub.unsubscribe(listener);
            };
        };

        const unsubscribe = handleTicketUpdates();
        return () => {
            unsubscribe();
        };
    }, []);

    const loadMore = () => {
        if (hasMore && !isLoading) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.0 && hasMore && !isLoading) {
            loadMore();
        }
    };

    const handleOpenTransferModal = async (ticket) => {
        setTicket(ticket);
        setTransferTicketModalOpen(true);
    };

    const handleCloseTransferTicketModal = () => {
        setTransferTicketModalOpen(false);
    };

    const handleSelectTicket = async (ticket) => {
        history.push(`/tickets/${ticket.id}`);
    };


    return (
        <div onScroll={handleScroll} className="box-tickets">
            {tickets.map((ticket) => (
                <React.Fragment key={ticket.id}>
                    <ListItem
                        dense
                        button
                        onClick={() => handleSelectTicket(ticket)}
                        selected={ticketId && +ticketId === ticket.id}
                        className={clsx("ticket", {
                            "pendingTicket": ticket.status === "pending",
                        })}
                    >
                        <Tooltip
                            arrow
                            placement="right"
                            title={ticket.queue?.name || "Sem departamento"}
                        >
                            <span
                                style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                                className="ticketQueueColor"
                            ></span>
                        </Tooltip>
                        <ListItemAvatar>
                            <Avatar src={ticket?.contact?.profilePicUrl} />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <Grid
                                    container
                                    spacing={4}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Grid item xs={9} style={{ marginBottom: '-10px' }}>
                                        <Typography
                                            noWrap
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                            style={{ fontWeight: "500" }}
                                        >
                                            {ticket?.contact?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {ticket.lastMessage && ticket.lastMessageTime && (
                                            <Typography
                                                className={
                                                    isSameDay(parseISO(ticket.lastMessageTime), new Date())
                                                        ? "lastMessageTime"
                                                        : "lastMessageDate"
                                                }
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {isSameDay(parseISO(ticket.lastMessageTime), new Date()) ? (
                                                    format(parseISO(ticket.lastMessageTime), "HH:mm")
                                                ) : (
                                                    format(parseISO(ticket.lastMessageTime), "dd/MM/yyyy")
                                                )}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            }
                            secondary={
                                <Grid
                                    container
                                    spacing={4}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    className="gridWithCustomSpacing"
                                >
                                    <Grid item xs={9}>
                                        <Typography
                                            className="contactLastMessageClosed"
                                            noWrap
                                            component="span"
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {cleanMessageVCard(ticket.lastMessage) || <br />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box display="flex" alignItems="center">
                                            <ButtonWithSpinner
                                                color="primary"
                                                variant="contained"
                                                className="reopenButton"
                                                size="small"
                                                loading={false}
                                                onClick={(e) => handleOpenTransferModal(ticket)}
                                                disableElevation
                                            >
                                                {i18n.t("ticketsList.buttons.accept")}
                                            </ButtonWithSpinner>
                                            <span className="closedBadge">
                                                <Icon type="solvedTicket" />
                                            </span>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            ))}

            {transferTicketModalOpen && (
                <TransferTicketModal
                    modalOpen={transferTicketModalOpen}
                    onClose={handleCloseTransferTicketModal}
                    ticketid={ticket?.id}
                    ticketWhatsappId={ticket?.whatsappId}
                    ticketQueueId={ticket?.queueId}
                    ticketStatus={ticket?.status}
                />
            )}
        </div>
    );
};

export default TicketListItemClosed;