import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Badge, Grid, Tooltip } from "@material-ui/core";
import PubSub from 'pubsub-js';
import TicketService from "../../services/Tickets";
import "./style.css";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";
import connectToSocket from "../../services/socket-io";

const TicketListItemOpen = ({ selectedQueueIds = [], selectedTagsIds = [], visibility }) => {
    const history = useHistory();
    const idGraf = localStorage.getItem("idGraf");
    const { ticketId } = useParams();
    const [tickets, setTickets] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 15;

    const fetchOpenTickets = async () => {
        setIsLoading(true);
        try {
            const data = await TicketService.getTicketsByStatus(pageSize, pageNumber, "open", selectedQueueIds, selectedTagsIds, visibility);
            const { tickets: newTickets, hasMore } = data;
            setTickets((prevTickets) => [...prevTickets, ...newTickets]);
            setIsLoading(false);
            setHasMore(hasMore);
        } catch (error) {
            console.error("Erro ao buscar tickets abertos:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (hasMore && !isLoading) {
            fetchOpenTickets();
        }
    }, [pageNumber, hasMore, tickets]);

    useEffect(() => {
        setTickets([]);
        setPageNumber(1);
        setHasMore(true);
    }, [selectedQueueIds, selectedTagsIds , visibility]);

    const loadMore = () => {
        if (hasMore && !isLoading) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.0 && hasMore && !isLoading) {
            loadMore();
        }
    };

    const handleSelectTicket = async (ticket) => {
        if (ticket.unreadMessages !== 0) {
            try {
                await TicketService.updateTicket(ticket.id, {
                    unreadMessages: 0,
                    isSelected: true,
                });
                PubSub.publish("TICKET_ACTION_SELECTED", { ticketId: ticket.id });
            } catch (error) {
                console.error("Erro ao atualizar o ticket:", error);
            }
        }
        history.push(`/tickets/${ticket.id}`);
    };

    useEffect(() => {
        const handleTicketUpdates = () => {
            const listener = PubSub.subscribe("TICKET_ACTION_UPDATED", async (_, { newStatus, ticketId }) => {

                setTickets((prevTickets) => {
                    if (newStatus === "pending" || newStatus === "closed" || newStatus === "deleted") {
                        const filteredTickets = prevTickets.filter((ticket) => ticket.id !== ticketId);
                        return filteredTickets;
                    }
                    return prevTickets;
                });

                if (newStatus === "transferTicket") {
                    try {
                        const updatedTicket = await TicketService.getTicketById(ticketId);
                        setTickets((prevTickets) => {

                            const existingTicketIndex = prevTickets.findIndex(ticket => ticket.id === Number(ticketId));
                            if (existingTicketIndex !== -1) {
                                const updatedTickets = [...prevTickets];
                                updatedTickets[existingTicketIndex] = updatedTicket;
                                return updatedTickets;
                            } else {
                                return [...prevTickets, updatedTicket];
                            }
                        });
                    } catch (error) {
                        console.error('Erro ao buscar o ticket atualizado:', error);
                    }
                }
            });

            return () => {
                PubSub.unsubscribe(listener);
            };
        };

        const unsubscribe = handleTicketUpdates();
        return () => {
            unsubscribe();
        };
    }, []);


    useEffect(() => {
        const socket = connectToSocket();

        socket.on(`ticket-open-${idGraf}`, (data) => {
            if (data.action === "update") {
                const updatedTicket = data.ticket;
                if (updatedTicket) {
                    setTickets((prevTickets) => {

                        const ticketIndex = prevTickets.findIndex(ticket => ticket.id === updatedTicket.id);
                        if (ticketIndex !== -1) {

                            const newTickets = [...prevTickets];
                            newTickets[ticketIndex] = updatedTicket;
                            return newTickets;
                        } else {

                            return [updatedTicket, ...prevTickets];
                        }
                    });
                }
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    return (
        <div onScroll={handleScroll} className="box-tickets">
            {tickets.length > 0 ? (
                tickets.map((ticket) => (
                    <React.Fragment key={ticket.id}>
                        <ListItem
                            dense
                            button
                            onClick={() => handleSelectTicket(ticket)}
                            selected={ticketId && +ticketId === ticket.id}
                            className="pendingTicket"
                        >
                            <Tooltip
                                arrow
                                placement="right"
                                title={ticket?.queue?.name || "Sem departamento"}
                            >
                                <span
                                    style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                                    className="ticketQueueColor"
                                ></span>
                            </Tooltip>
                            <ListItemAvatar>
                                <Avatar src={ticket?.contact?.profilePicUrl} />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Grid
                                        container
                                        spacing={4}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={9} style={{ marginBottom: '-10px' }}>
                                            <Typography
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                style={{ fontWeight: "500" }}
                                            >
                                                {ticket?.contact?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} className="box-lastMessage-lastTime">
                                            {ticket.lastMessage && ticket.lastMessageTime && (
                                                <Typography
                                                    className={
                                                        isSameDay(parseISO(ticket.lastMessageTime), new Date())
                                                            ? "lastMessageTime"
                                                            : "lastMessageDate"
                                                    }
                                                    component="span"
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {isSameDay(parseISO(ticket.lastMessageTime), new Date()) ? (
                                                        format(parseISO(ticket.lastMessageTime), "HH:mm")
                                                    ) : (
                                                        format(parseISO(ticket.lastMessageTime), "dd/MM/yyyy")
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                                secondary={
                                    <Grid
                                        container
                                        spacing={4}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        className={ticket.status === "closed" ? "gridWithCustomSpacing" : ""}
                                    >
                                        <Grid item xs={9}>
                                            <Typography
                                                className={
                                                    ticket.status === "closed"
                                                        ? "contactLastMessageClosed"
                                                        : "contactLastMessage"
                                                }
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {cleanMessageVCard(ticket.lastMessage) || <br />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Badge
                                                badgeContent={ticket.unreadMessages}
                                                classes={{ badge: "badgeStyle" }}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>

                ))
            ) : (

                <div class="no-tickets-div">
                    <span class="no-tickets-title">
                        Não há nada aqui!
                    </span>
                    <p class="no-tickets-text">
                        Não há nenhum ticket em atendimento
                    </p>
                </div>

            )}

        </div>
    );
};

export default TicketListItemOpen;
